<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="250px"
          max-width="200px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>

      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0 pb-0 left-panel-login"
        >
        </v-col>

        <v-col
          lg="4"
          cols="12"
          class="d-flex align-center auth-bg pt-16"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    Administrator
                  </p>
                  <p class="mb-2">
                    Please sign-in to your account and start the adventure
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form>
                    <v-text-field
                      v-model="username"
                      outlined
                      label="Username"
                      placeholder="john@example.com"
                      class="mb-3"
                      :hide-details="formErrorFields.usernameErrorMessage.length === 0"
                      :error-messages="formErrorFields.usernameErrorMessage"
                      @keydown="formError().remove('username', formErrorFields)"
                    ></v-text-field>

                    <v-text-field
                      v-model="password"
                      outlined
                      :type="isPasswordVisible ? 'text' : 'password'"
                      label="Password"
                      placeholder="············"
                      :append-icon="isPasswordVisible ? icons.mdiEyeOffOutline : icons.mdiEyeOutline"
                      hide-details
                      @click:append="isPasswordVisible = !isPasswordVisible"
                      @keypress.enter="signinWasClicked"
                    ></v-text-field>

                    <div class="d-flex align-center justify-space-between flex-wrap">

                      <!-- forget link -->
                      <router-link
                        :to="{name:'auth-forgot-password'}"
                        class="mt-1"
                      >
                        Forgot Password?
                      </router-link>
                    </div>

                    <v-btn
                      block
                      class="mt-6"
                      color="success"
                      :loading="loading"
                      @click="signinWasClicked"
                    >
                      Login
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiEyeOutline, mdiEyeOffOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import signin from '@/composables/login/signin'
import formError from '@/composables/formErrorHandler/formError'
import snackbar from '@/components/SnackBar.vue'
import { useRouter } from '@/@core/utils'

export default {
  components: {
    snackbar,
  },

  setup() {
    const loading = ref(false)
    const isPasswordVisible = ref(false)
    const username = ref('')
    const password = ref('')
    const { router } = useRouter()
    const message = ref('')
    const formErrorFields = ref({
      usernameErrorMessage: '',
      passwordErrorMessage: '',
    })

    const { go, errorData } = signin()

    const signinWasClicked = async () => {
      loading.value = true
      await go(username, password, formErrorFields, router)
      if (errorData.value.status === 403) {
        await localStorage.setItem('userId', errorData.value.data)
        router.replace('/otp')
      } else {
        message.value = errorData.value.message !== undefined ? errorData.value.message : ''
        loading.value = false
      }
    }

    return {
      isPasswordVisible,
      username,
      password,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiEyeOutline,
        mdiEyeOffOutline,
      },

      signinWasClicked,
      formErrorFields,
      formError,
      message,
      loading,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

<style scoped>
.left-panel-login {
  background-image: url("../../assets/images/svg/background.svg");
  background-size: contain;
  opacity: 0.6;
}
</style>
