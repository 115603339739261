<template>
  <v-card class="elevation-1">
    <v-card-title>
      Stockist
      <v-row>
        <v-col cols="12" md="4" lg="4" offset-md="8" offset-lg="8">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-2"
            @keypress.enter="searchTable=search"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :search="searchTable"
      :options.sync="options"
      :server-items-length="totalItems"
      :loading="loading"
      dense
    >
      <template #[`item.action`]="{ item }">
        <v-btn
          x-small
          text
          color="success"
          @click="editItem(item)"
        >
          Edit
        </v-btn>
        <v-btn
          x-small
          text
          color="error"
          @click="deleteItem(item)"
        >
          Delete
        </v-btn>
      </template>
    </v-data-table>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import crud from '@/composables/crud'
import getData from '@/composables/getData'
import snackbar from '@/components/SnackBar.vue'

export default {
  props: ['uniqueID'],
  setup(props, { emit }) {
    const search = ref('')
    const headers = ref([
      { text: 'Stockist Code', align: 'start', value: 'stockist_code' },
      { text: 'Stockist Name', align: 'start', value: 'stockist_name' },
      { text: 'Username', align: 'start', value: 'username' },
      { text: 'Type', align: 'start', value: 'type' },
      { text: 'Mobile Number', align: 'start', value: 'mobile_number' },
      { text: 'Email', align: 'start', value: 'email' },
      { text: 'Province', align: 'start', value: 'province_name' },
      { text: 'City', align: 'start', value: 'municipality_name' },
      { text: 'Barangay', align: 'start', value: 'barangay_name' },
      { text: 'Address', align: 'start', value: 'address' },
      { text: 'Date Created', align: 'start', value: 'created_at' },
      { text: 'Action', align: 'middle', value: 'action' },
    ])
    const items = ref([])
    const totalItems = ref(0)
    const searchTable = ref('')
    const options = ref({})
    const loading = ref(false)

    const { remove, responseMessageStatus, responseMessage } = crud()
    const listUri = `${process.env.VUE_APP_URI}/api/admin/stockists`

    watch(() => props.uniqueID, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(options, () => {
      getData(listUri, items, totalItems, loading, options, search)
    })

    watch(searchTable, () => {
      options.value.page = 1
      getData(listUri, items, totalItems, loading, options, search)
    })

    const editItem = item => {
      emit('passData', item)
    }

    const deleteItem = async item => {
      await remove(`${process.env.VUE_APP_URI}/api/admin/stockists/${item.id}`, loading)
      await getData(listUri, items, totalItems, loading, options, search)
    }

    return {
      search,
      headers,
      items,
      searchTable,
      options,
      totalItems,
      loading,
      editItem,
      deleteItem,
      responseMessageStatus,
      responseMessage,
    }
  },

  components: {
    snackbar,
  },
}
</script>
