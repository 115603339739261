<template>
    <v-snackbar
      v-model="snackbar"
      :color="color"
      top
      transition="scroll-y-transition"
    >
      {{ message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="close"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import { onMounted, ref } from '@vue/composition-api'

export default {
  props: {
    text: String,
    show: Boolean,
    color: String,
  },
  setup(props, { emit }) {
    const message = ref(props.text)
    const snackbar = ref(props.show)

    const close = () => {
      message.value = ''
      snackbar.value = false
      emit('close', true)
    }

    onMounted(() => {
      setTimeout(() => {
        emit('close', true)
      }, 4000)
    })

    return {
      snackbar,
      close,
      message,
    }
  },
}

</script>
