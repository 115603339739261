<template>
  <div class="auth-wrapper auth-v2">
    <div class="auth-inner">
      <!-- brand logo -->
      <router-link
        to="/"
        class="brand-logo d-flex align-center"
      >
        <v-img
          :src="appLogo"
          max-height="250px"
          max-width="200px"
          alt="logo"
          contain
          class="me-3 "
        ></v-img>
      </router-link>
      <!--/ brand logo -->

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0 left-panel-login"
        >
        </v-col>

        <v-col
          lg="4"
          class="d-flex align-center auth-bg pt-16"
        >
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              lg="12"
              class="mx-auto"
            >
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    One Time Password
                  </p>
                  <p class="mb-2">
                    Enter your otp
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form>
                    <v-text-field
                      v-model="otp"
                      outlined
                      label="OTP"
                      class="mb-4"
                      type="password"
                      :error-messages="formErrorFields.otpErrorMessage"
                      @keydown="formError().remove('otp', formErrorFields)"
                      @keypress.enter="confirmWasClicked"
                    ></v-text-field>

                    <v-btn
                      block
                      color="success"
                      @click="confirmWasClicked"
                    >
                      Confirm
                    </v-btn>
                  </v-form>
                </v-card-text>

                <v-card-actions class="d-flex justify-center align-center">
                  <router-link
                    :to="{name:'auth-login'}"
                    class="d-flex align-center text-sm"
                  >
                    <v-icon
                      size="24"
                      color="primary"
                    >
                      {{ icons.mdiChevronLeft }}
                    </v-icon>
                    <span>Back to login</span>
                  </router-link>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>

    <snackbar
      v-if="message.length > 0"
      :show="message.length > 0"
      :text="message"
      @close="message=''"
    >
    </snackbar>
  </div>
</template>

<script>
import axios from 'axios'
import Cookies from 'universal-cookie'
import { mdiChevronLeft } from '@mdi/js'
import { ref } from '@vue/composition-api'
import themeConfig from '@themeConfig'
import formError from '@/composables/formErrorHandler/formError'
import snackbar from '@/components/SnackBar.vue'
import { useRouter } from '@/@core/utils'
import fnx from '@/functions/fn'

export default {
  components: { snackbar },
  setup() {
    const { router } = useRouter()
    const loading = ref(false)
    const message = ref('')
    const isPasswordVisible = ref(false)
    const otp = ref('')
    const formErrorFields = ref({
      otpErrorMessage: '',
    })

    const confirmWasClicked = async () => {
      loading.value = true
      try {
        const response = await axios.post(`${process.env.VUE_APP_URI}/api/auth/admin/confirm-otp`, {
          userId: localStorage.getItem('userId'),
          otp: otp.value
        })
        
        const cookies = new Cookies()

        cookies.set('access_token', response.data.access_token, {
          path: '/',
          sameSite: true,
        })

        cookies.set('expires_in', response.data.expires_in, {
          path: '/',
          sameSite: true,
        })

        cookies.set('user', fnx.base64_encode(response.data.user), {
          path: '/',
          sameSite: true,
        })

        router.replace({ path: '/dashboard' })
        
      } catch (error) {
        console.log(error)
        loading.value = false
        if (error.response.status === 422) {
          formErrorFields.value.otpErrorMessage = error.response.data.errors.otp
        } else {
          message.value = error.response.data.message
        }
      }
    }

    return {
      confirmWasClicked,
      isPasswordVisible,
      otp,
      formError,
      formErrorFields,
      message,
      loading,

      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,

      icons: {
        mdiChevronLeft,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/pages/auth.scss';
</style>

<style scoped>
.left-panel-login {
  background-image: url("../../assets/images/svg/background.svg");
  background-size: contain;
  opacity: 0.6;
}
</style>
